import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import ProductList from '../../../components/productList'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
        }
    },

}))

const ProductListPageCats = ({ data, location }) => {
    const classes = useStyles()
    /* eslint eqeqeq: 0 */
    const catWetfood = data.allContentfulProduct.nodes.filter(el => (el.for_cats & el.type == 'Yaş Mama'))
    const catDryFood = data.allContentfulProduct.nodes.filter(el => (el.for_cats & el.type == 'Kuru Mama'))
    const catLitters = data.allContentfulProduct.nodes.filter(el => (el.for_cats & el.type == 'Kedi Kumu'))
    return (
        <Layout nodeLocale='tr-TR' location={location}>
            <SEO lang='tr-TR' title='Kediler için birbirinden lezzetli mamalarımız' location={location} />
            <div className={classes.root}>
                <Grid container spacing={4} id='cat-dry'>
                    <ProductList products={catDryFood} title='Kuru Mamalar' nodeLocale='tr-TR' />
                </Grid>

                <Grid container spacing={4} id='cat-wet'>
                    <ProductList products={catWetfood} title='Yaş Mamalar' nodeLocale='tr-TR' />
                </Grid>
                <Grid container spacing={4} id='cat-litters'>
                    <ProductList products={catLitters} title='Kedi Kumları' nodeLocale='tr-TR' />
                </Grid>
            </div>
        </Layout>
    )
}

export const query = graphql`
query ProductListCatQuery {
    allContentfulProduct(filter: {brand: {brandName: {eq: "Prochoice"}, node_locale: {eq: "tr-TR"}}}) {
      nodes {
        ageGroup
        breeds
        contentful_id
        for_cats
        name
        id
        segment
        slug
        teaser
        type
        labels {
            id
            name
            label
          }
        packagePhoto {
            fluid(maxHeight: 360, quality: 100) {
                ...GatsbyContentfulFluid,
                
            }
        }
      }
    }
}
`
export default ProductListPageCats